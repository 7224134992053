.footer {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  color: #fff;
}

.footer span {
  font-weight: 600;
  color: #61dafb;
}