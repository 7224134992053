.tasklist {
  height: 100px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  color: #000;
}

.container_btn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: start;
}

.container_btn button {
  padding: 8px;
  cursor: pointer;
  background-color: #ddd;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: .5s;
}

/* .container_btn button:nth-child(1):hover {
  background-color: #282c34;
  border: 1px solid #282c34;
  color: #fff;
} */

.container_btn button:nth-child(2) {
  background-color: #61dafb;
  border: 1px solid #61dafb;
  color: #000;
  cursor: pointer;
}

.container_btn button:hover {
  background-color: #fff;
  border-color: #282c34;
}