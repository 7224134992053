.modal {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 50px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: start;
}

.form label {
  margin-bottom: 10px;
}